import React from 'react';
import Image from 'gatsby-image';
import Fade from 'react-reveal/Fade';
import PropTypes from 'prop-types';

import './styles.scss';

const PortfolioItem = ({ data, index }) => (
  <div className="w-full flex md:w-1/2 lg:w-1/2 xl:w-1/3 p-card px-3 py-5">
    <Fade bottom duration={200} delay={index * 100}>
      <div className="rounded w-full overflow-hidden shadow-lg">
        <Image
          className="w-full"
          fluid={data.thumbnail.fluid}
          alt={data.title}
        />
          <div className="px-6 py-4">
            <div className="text-2xl mb-2 font-bold font-niramit text-primary-magenta">{data.title}</div>
            <p className="text-base font-niramit text-alpha-purple">{data.shortDescription}</p>
          </div>
          <div className="px-6 py-4">
            {data.tags && data.tags.map(({ title: tag }) => (
              <span
                className="inline-block bg-sweet-blue rounded-full px-3 py-1 text-sm text-gray-600 font-bold  mr-2 my-1">{tag}</span>
            ))}
          </div>
      </div>
    </Fade>
  </div>
);

PortfolioItem.propTypes = {
  data: PropTypes.shape({}),
  delay: PropTypes.number,
}

PortfolioItem.defaultProps = {
  data: {},
  delay: 0
}

export default PortfolioItem;
