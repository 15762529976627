import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from 'gatsby-image';
import ScrollableAnchor from 'react-scrollable-anchor'
import PortfolioItem from "./PortfolioItem"
import Container from "../container"
import Filter from "./Filter"
import headerLeaf from '../../images/leaf.png';

import './styles.scss';
import { Fade } from "react-reveal"

export const query = graphql`
    query {
        allContentfulPortfolioItem {
            totalCount
            nodes {
                tags {
                    title
                }
                contentful_id
                title
                shortDescription
                category
                thumbnail {
                    fluid(maxWidth: 570, maxHeight: 570, quality: 100) {
                        base64
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        sizes
                    }
                }
            }
        }
        
        file(relativePath: { eq: "portfolio-bg.png" }) {
            childImageSharp {
                fixed(width: 1000, height: 1000, quality: 100) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
    }
`

const Portfolio = () => {

  const { allContentfulPortfolioItem: { nodes }, file: { childImageSharp: { fixed: portfolioBG }} } = useStaticQuery(query);

  const [selectedCategory, selectCategory] = useState('all');
  const [filteredNodes, filterNodes] = useState(nodes);


  const onFilterClick = category => {
    selectCategory(category);

    if (category === 'all') {
      filterNodes(nodes);
      return
    }

    filterNodes(nodes.filter(node => node.category === category));
  }


  return (
    <div className="pt-12 xl:pt-32 mb-12 xl:mb-24 overflow-hidden">
      <Container>
        <ScrollableAnchor id='our-work'>
          <div />
        </ScrollableAnchor>

        <div className="flex justify-start flex-col w-full xl:w-5/12">
          <Fade top duration={1000}>
            <img style={{ width: 61, height: 112 }} src={headerLeaf} alt="leaf" />
          </Fade>
          <Fade bottom duration={1000}>
            <h2 className="font-niramit leading-tight text-secondary-magenta text-3xl md:text-5xl">Some Of Our Recent Projects</h2>
          </Fade>
        </div>

        <div className="mt-12 lg:mt-24 flex justify-center relative">
          <Fade bottom duration={500}>
            <div className="flex flex-col md:flex-row w-full md:w-auto lg:w-auto md:inline-block">
              <Filter
                onClick={onFilterClick}
                selectedCategory={selectedCategory}
                categories={nodes}
              />
            </div>
          </Fade>

          <div  className="absolute bg-portfolio w-full md:w-auto">
            <Image fixed={portfolioBG} />
          </div>
        </div>


      </Container>

      <Container>
        <div className="pb-6 md:mb-16 mt-12 px-6">
          <div className="flex flex-wrap pb-4">
            {filteredNodes.map((node, index) => (
              <PortfolioItem
                data={node}
                index={index}
                key={`${index}${node.contentful_id}`} />
            ))}
          </div>
        </div>
      </Container>

    </div>
  )
}

export default Portfolio;
