import React, { Fragment, PureComponent, useState } from "react"
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSlidersH } from '@fortawesome/free-solid-svg-icons'

import './styles.scss';

const Filter = ({ categories, selectedCategory, onClick }) => {

  const [isShown, onVisibilityChange] = useState(false);

  const onFilterToggle = () => {
    onVisibilityChange(!isShown);
  }

  const onCategoryChange = category => {
    onVisibilityChange(false);
    onClick(category);
  }

  return (
    <Fragment>

      <button onClick={onFilterToggle} type="button" className={`flex items-center justify-between w-full px-4 md:hidden filter-mobile-toggle py-4 px-8 outline-none ${isShown && 'active'}`}>
        <span className="filter-selected active">{selectedCategory}</span>

        <span className="filter-toggle text-secondary-magenta">
            <FontAwesomeIcon icon={faSlidersH} />
          </span>
      </button>

      <ul className={`filter-list flex-col items-start md:flex md:flex-row md:justify-between px-4 md:px-0 ${isShown ? 'flex mobile' : 'hidden'}`}>
        <li className="w-full md:w-auto">
          <button onClick={() => onCategoryChange('all')} type="button" className={`capitalize w-full md:w-auto btn btn-sm btn-filter text-left py-4 px-8 md:p-0 ${selectedCategory === 'all' && 'btn-primary'}`}>All</button>
        </li>
        {[...new Set(categories.map(item => item.category))].map(category => (
          <li className="w-full md:w-auto" key={category}>
            <button
              type="button"
              onClick={() => onCategoryChange(category)}
              className={`w-full md:w-auto btn btn-sm btn-filter capitalize font-niramit text-secondary-magenta text-left py-4 px-8 md:p-0 ${selectedCategory === category && 'btn-primary'}`}>{category}</button>
          </li>
        ))}
      </ul>
    </Fragment>
  )
}

Filter.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape()),
  onClick: PropTypes.func.isRequired,
  selectedCategory: PropTypes.string,
}

Filter.defaultProps = {
  categories: [],
  selectedCategory: 'all'
}

export default Filter;
